import React from 'react';
import Layout from '../components/Layout';
import Title from '../components/Title/Title';
import Accordion from '../components/Accordion/Accordion';
import References from '../components/References/References';
import clsx from 'clsx';
import DynamicContent from '../components/DynamicContent/DynamicContent';
import Banner from '../components/Banner/Banner';
import CtaDescList from '../components/CtaDescList/CtaDescList';
import ComplexTabs  from '../components/ComplexTabs/ComplexTabs';
import TabItem from '../components/ComplexTabs/TabItem';
import Footnotes from '../components/Footnotes/Footnotes';
import ExitLink from '../components/ExitLink/ExitLink';

import EfficacyFirstTrialDesignMobile from '../images/efficacy-first/efficacyFirstTrialDesignMobile.inline.svg';
import EfficacyFirstTrialDesignDesktop from '../images/efficacy-first/efficacyFirstTrialDesignDesktop.inline.svg';
import EfficacyReductionMobile from '../images/efficacy-first/efficacy48ReductionMobile.inline.svg';
import EfficacyReductionDesktop from '../images/efficacy-first/efficacy48ReductionDesktop.inline.svg';
import EfficacyFirstSecondaryEndpointMobile from '../images/efficacy-first/efficacyFirstSecondaryEndpointMobile.inline.svg';
import EfficacyFirstSecondaryEndpointDesktop from '../images/efficacy-first/efficacyFirstSecondaryEndpointDesktop.inline.svg';
import EfficacyFirstAchieveResponseDesktop from '../images/efficacy-first/efficacyFirstAchieveResponseDesktop.inline.svg';
import EfficacyFirstAchieveResponseMobile from '../images/efficacy-first/efficacyFirstAchieveResponseMobile.inline.svg';
import EfficacyFinalDesktop from '../images/efficacy-first/efficacyFinalDesktop.inline.svg';
import EfficacyFinalMobile from '../images/efficacy-first/efficacyFinalMobile.inline.svg';
import EnlargeIcon from '../images/enlargeIcon.inline.svg';
import JumpLink from '../components/JumpLink/JumpLink';

import { GtagHead } from '../components/GTAG';
export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>Efficacy | LOQTORZI® (toripalimab-tpzi)</title>
      <meta name='description' content='Learn more about the LOQTORZI® efficacy demonstrated in the JUPITER-02 trial.' />
    </>
  )
}

export default function EfficacyFirst() {
  const isiRef = React.createRef();
  return (
    <Layout ref={isiRef}>
      <Title pageName={'Efficacy'}>
        EFFICACY:
      </Title>

      <DynamicContent header={<h2>JUPITER-02 TRIAL DESIGN</h2>}>
        <div className='wrapperContent'>
          <div className='paragraph mt-[2rem]'>
          <span>
            The efficacy of LOQTORZI<sup>®</sup> in combination with cisplatin and
            gemcitabine was investigated in JUPITER-02, a randomized, multicenter,
            single-region,* double-blind, placebo-controlled trial in 289 adult
            patients with metastatic or recurrent locally advanced NPC who had not
            received previous systemic chemotherapy for recurrent or metastatic
            disease.<sup>1&dagger;</sup>
          </span>
          </div>
          <div className='flex justify-end gap-[1rem] my-[1rem] font-MontserratRegular text-[1rem] text-gray-250 items-center tablet:hidden'>
            <span>PINCH TO ENLARGE</span>
            <EnlargeIcon></EnlargeIcon>
          </div>
          <EfficacyFirstTrialDesignMobile
            className={clsx('w-full h-auto m-auto mb-[3rem] tablet:hidden')}
          />
          <EfficacyFirstTrialDesignDesktop
            className={clsx(
              'hidden w-full h-auto m-auto mb-[3rem] tablet:block'
            )}
          />
          <div className='paragraph'>
            <span>
              Treatment with LOQTORZI<sup>®</sup> or placebo continued until disease progression per RECIST v1.1, unacceptable toxicity, or a maximum of 2 years. The primary end point was BIRC-assessed PFS per RECIST v1.1 criteria. The secondary end points were BIRC-assessed ORR, BIRC-assessed DOR, and OS.<sup>1,2</sup>
            </span>
          </div>

          <Footnotes items={[{
              bullet: '*',
              text: <span>The JUPITER-02 trial was conducted across 35 sites in mainland China, Taiwan, and Singapore.<sup>2</sup></span>
            }, {
              bullet: <sup>†</sup>,
              text: <span>Patients with recurrent NPC after treatment with curative intent were required to have an interval of at least 6 months between the last dose of radiotherapy or chemotherapy and recurrence. Patients with autoimmune disease, other than stable hypothyroidism or type 1 diabetes, and patients who required systemic immunosuppression were ineligible.<sup>1</sup></span>
            }, {
              bullet: '',
              text: <span>BIRC=blinded independent review committee; DOR=duration of response; IV=intravenous; ORR=overall response rate; OS=overall survival; PFS=progression-free survival; Q3W=every 3 weeks; R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma; RECIST=Response Evaluation Criteria in Solid Tumors.</span>
            }
          ]} />

        </div>
        <Banner>
          <span>
            LOQTORZI<sup>®</sup>: first and only treatment with a successful
            registrational trial in R/M NPC that has led to FDA approval
            <sup>3</sup>
          </span>
        </Banner>
      </DynamicContent>

      <Accordion
        GlobalWrapperClassname={'pb-[3rem] pt-[2rem]'}
        items={[
          {
            title: (
              <>
                Key eligibility and <br className='tablet:hidden' />
                exclusion criteria<span><sup>2</sup></span>
              </>
            ),
            content: (
              <div>
                <p>
                  <strong>Key eligibility criteria</strong> included primary metastatic or recurrent locally advanced NPC after curative-intent therapy, treatment naïve for R/M NPC, ECOG PS 0-1, 18-75 years of age, and measurable disease per RECIST v1.1.
                </p>
                <p>
                  <strong>Key exclusion criteria</strong> included a history of severe hypersensitivity reactions to any monoclonal antibody, cisplatin or gemcitabine, or any ingredient of toripalimab; active or untreated central nervous system metastases; previous monoclonal antibody treatment targeting PD-1/PD-L1/CTLA4; a history of bone marrow or solid organ transplantation; or a history of protocol-specified autoimmune disease.
                </p>

                <Footnotes className='mt-[2.6rem]' items={[
                  {
                    bullet: '',
                    text: <span>CTLA4=cytotoxic T-lymphocyte–associated protein 4; ECOG PS=Eastern Cooperative Oncology Group performance status; PD-L1=programmed death-ligand 1.</span>
                  }
                ]} />
              </div>
            ),
          },
          {
            title: (
              <>
                Key demographics<span><sup>1</sup></span>
              </>
            ),
            content: (
              <section>
                <div className='desktop:grid desktop:grid-cols-4 desktop:gap-[2%]'>
                  <ul className='bullet-list max:min-w-[28rem]'>
                    <li>
                      Median age was 48 years (range: 19 to 72); 4.8% were ≥65 years
                    </li>
                    <li>83% male</li>
                    <li>100% Asian</li>
                  </ul>
                  <ul className='bullet-list large:w-[20rem]'>
                    <li>57% ECOG PS of 0</li>
                    <li>86% had metastatic disease</li>
                  </ul>
                  <ul className='bullet-list'>
                    <li>
                      Histological subtypes:
                      <div>
                        <ul className='bullet-list-dash leading-none'>
                          <li>98% nonkeratinizing</li>
                          <li>1% keratinizing squamous<br />cell carcinoma</li>
                          <li>1% not identified</li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                  <ul className='bullet-list'>
                    <li>
                      Previous therapy:
                      <div>
                        <ul className='bullet-list-dash'>
                          <li>
                          Approximately 59% received ≥1 prior systemic therapy for locally advanced disease, and 60% received prior radiation therapy
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </section>
            ),
          },
        ]}
      />

      <ComplexTabs className='!pt-0'>
        <TabItem key='ComplexTab1' title='PFS'>
          <div>
            <DynamicContent
              header={ <h2>Progression-free survival: interim analysis (primary end point)</h2> }>
              <div className='wrapperContent'>
                <div className='title mt-[2rem]'>
                  <h3>
                    LOQTORZI<sup>®</sup> + chemo significantly reduced the risk of progression<sup>1</sup><sup></sup>
                  </h3>
                </div>
                <div className='paragraph font-MontserratMedium'>
                  <div className='text-center tablet:text-left'>
                    LOQTORZI<sup>®</sup> + chemo delivered a BIRC-assessed mPFS of 11.7 months vs 8 months with placebo + chemo<sup>1,2</sup>*
                  </div>
                </div>
                <div className='flex justify-end gap-[1rem] my-[1rem] font-MontserratRegular text-[1rem] text-gray-250 items-center tablet:hidden'>
                  <span>PINCH TO ENLARGE</span>
                  <EnlargeIcon></EnlargeIcon>
                </div>
                <EfficacyReductionMobile
                  className={clsx('w-full h-auto m-auto tablet:hidden')}
                />
                <EfficacyReductionDesktop
                  className={clsx('hidden w-full h-auto m-auto tablet:block')}
                />

                <Footnotes className={'mt-[2rem]'} items={[
                  {
                    bullet: '*',
                    text: <span>Cutoff date for interim analysis was May 30, 2020.<sup>1</sup></span>
                  }, {
                    bullet: <sup>&dagger;</sup>,
                    text: <span>Based on the stratified Cox proportional-hazards model using the stratification factors at randomization, ECOG performance status, and disease stage.<sup>1</sup></span>
                  }, {
                    bullet: <sup>&#8225;</sup>,
                    text: <span>Two-sided <i>P</i> value, based on the stratified log-rank test, as compared with an alpha boundary of 0.010.<sup>1</sup></span>
                  }
                ]} />

              </div>
            </DynamicContent>
            <DynamicContent isBorderLeft={false} header={<h2>Progression-free survival: <br className='tablet:hidden'/>final analysis</h2>}>
              <div className='wrapperContent'>
                <div className='title mt-[2rem]'><h3>LOQTORZI<sup>®</sup> + chemo offered durable protection from progression<sup>4,5</sup><sup>§</sup></h3></div>
                  <div className='font-MontserratMedium text-center tablet:text-left'>Final PFS by BIRC per RECIST v1.1</div>
                  <div className='flex justify-end gap-[1rem] my-[1rem] font-MontserratRegular text-[1rem] text-gray-250 items-center tablet:hidden'>
                    <span>PINCH TO ENLARGE</span>
                    <EnlargeIcon></EnlargeIcon>
                  </div>
                  <EfficacyFinalMobile className={ clsx('w-full h-auto mt-[.6rem] m-auto tablet:hidden') } />
                  <EfficacyFinalDesktop className={ clsx('hidden w-full h-auto mt-[1.5rem] mx-auto tablet:block') }/>
              </div>
              <div className='wrapperContent'>
                <Footnotes items={[{
                    bullet: <sup>§</sup>,
                    text: <span>Cutoff date for final analysis was June 8, 2021.<sup>4,5</sup></span>
                  },
                  {
                    bullet: <sup>‖</sup>,
                    text: <span>Patients were treated with LOQTORZI<sup>®</sup> + cisplatin and gemcitabine for up to six 21-day cycles followed by LOQTORZI<sup>®</sup> monotherapy.<sup>1</sup></span>
                  },
                  {
                    bullet: <sup>¶</sup>,
                    text: <span>Patients were treated with placebo + cisplatin and gemcitabine for up to six 21-day cycles followed by placebo monotherapy.<sup>1</sup></span>
                  },
                  {
                    bullet: '',
                    text: <span>BIRC=blinded independent review committee; CI=confidence interval; ECOG=Eastern Cooperative Oncology Group; HR=hazard ratio; mPFS=median progression-free survival; NE=not estimable; RECIST=Response Evaluation Criteria in Solid Tumors.</span>
                  }
                ]} />
              </div>
            </DynamicContent>
          </div>
        </TabItem>
        <TabItem key='ComplexTab2' title='OS'>
            <DynamicContent
                header={<h2>Overall survival: final analysis (secondary end point)*</h2>}>
                <div className='wrapperContent'>
                  <div className='title mt-[2rem]'>
                    <h3>
                      Final overall survival analysis showed reduced risk of death by 37%<sup>1,4</sup>
                    </h3>
                  </div>
                  <div className='paragraph font-MontserratMedium'>
                    <span>
                      Median OS was not reached in the LOQTORZI<sup>®</sup> + chemo arm compared with 33.7 months in the placebo + chemo arm<sup>1,4</sup>
                    </span>
                  </div>

                  <div className='flex justify-end gap-[1rem] my-[1rem] font-MontserratRegular text-[1rem] text-gray-250 items-center tablet:hidden'>
                    <span>PINCH TO ENLARGE</span>
                    <EnlargeIcon></EnlargeIcon>
                  </div>
                  <EfficacyFirstSecondaryEndpointMobile
                    className={clsx('w-[100%] h-auto m-auto mb-[2rem] tablet:hidden')}
                  />
                  <EfficacyFirstSecondaryEndpointDesktop
                    className={clsx('hidden w-full h-auto m-auto mb-[2rem] tablet:block')}
                  />

                  <ul className='bullet-list'>
                    <li>
                      A significant improvement in OS was observed in patients treated with LOQTORZI<sup>®</sup> + chemo versus placebo + chemo<sup>1</sup>
                      <ul className='bullet-list-dash'>
                        <li>Median survival follow-up of 36 months</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className='wrapperContent'>
                  <Footnotes items={[
                    {
                      bullet: '*',
                      text: <span>OS results are based on the final analysis with a data cutoff of November 18, 2022.<sup>1</sup></span>
                    },
                    {
                      bullet: <sup>†</sup>,
                      text: <span>Two-sided <em>P</em> value, based on the stratified log-rank test, as compared with an alpha boundary of 0.049995.<sup>1</sup></span>
                    },
                    {
                      bullet: <sup>‡</sup>,
                      text: <span>Patients were treated with LOQTORZI<sup>®</sup> + cisplatin and gemcitabine for up to six 21-day cycles followed by LOQTORZI<sup>®</sup> monotherapy.<sup>1</sup></span>
                    },
                    {
                      bullet: <sup>§</sup>,
                      text: <span>Patients were treated with placebo + cisplatin and gemcitabine for up to six 21-day cycles followed by placebo monotherapy.<sup>1</sup></span>
                    },
                    {
                      bullet: '',
                      text: <span>CI=confidence interval; HR=hazard ratio; NE=not estimable; NR=not reached; PD-L1=programmed death-ligand 1.</span>
                    }
                  ]} />
                </div>
              </DynamicContent>
        </TabItem>
        <TabItem key='ComplexTab3' title='ORR/DOR'>
            <DynamicContent header={ <h2>Overall response rate and Duration of Response (secondary end points)</h2> }>
              <div className='wrapperContent'>
                <div className='title pt-[2rem]'>
                  <h2 className='h3 text-left normal-case'>
                    77% of patients treated with LOQTORZI<sup>®</sup> + chemo achieved a response<sup>1</sup>
                  </h2>
                </div>
                <div className='paragraph font-MontserratMedium !text-center tablet:!text-left'>
                  <span>
                    BIRC-assessed ORR and DOR
                  </span>
                </div>
                <div className='flex justify-end gap-[1rem] my-[1rem] font-MontserratRegular text-[1rem] text-gray-250 items-center tablet:hidden'>
                  <span>PINCH TO ENLARGE</span>
                  <EnlargeIcon></EnlargeIcon>
                </div>
                <EfficacyFirstAchieveResponseMobile
                  className={clsx('w-full h-auto m-auto tablet:hidden')}
                />
                <EfficacyFirstAchieveResponseDesktop
                  className={clsx('hidden w-full h-auto m-auto tablet:block')}
                />
              </div>
              <Banner>
                <span>
                  Nearly 1 in 5 patients experienced <br className='tablet:hidden'/>a complete response rate with LOQTORZI<sup>®</sup> + chemo<sup>1</sup>
                </span>
              </Banner>
              <div className='wrapperContent mt-[3rem]'>
                <Footnotes items={[
                  {
                    bullet: '*',
                    text: <span>Patients were treated with LOQTORZI<sup>®</sup> + cisplatin and gemcitabine for up to six 21-day cycles followed by LOQTORZI<sup>®</sup> monotherapy.<sup>1</sup></span>
                  },{
                    bullet: <sup>†</sup>,
                    text: <span>Patients were treated with placebo + cisplatin and gemcitabine for up to six 21-day cycles followed by placebo monotherapy.<sup>1</sup></span>
                  },{
                    bullet: <sup>‡</sup>,
                    text: <span>ORR results are based on the prespecified interim analysis with data cutoff of May 30, 2020.<sup>1</sup></span>
                  },{
                    bullet: <sup>§</sup>,
                    text: <span>Two-sided <em>P</em> value based upon Cochran-Mantel-Haenszel test.<sup>1</sup></span>
                  },{
                    bullet: '',
                    text: <span>BIRC=blinded independent review committee; CI=confidence interval; DOR=duration of response; mOS=median overall survival; NE=not estimable; NR=not reached; ORR=overall response rate.</span>
                  }
                ]} />
              </div>
            </DynamicContent>
        </TabItem>
      </ComplexTabs>
      <JumpLink content={{title:'See JUPITER-02 safety', link:'/loqtorzi-safety-first-line-treatment/', class:false}} />
      <References
        items={[
          <span>
            LOQTORZI<sup>®</sup> (toripalimab-tpzi) Prescribing Information. Redwood City, CA: Coherus BioSciences, Inc.
          </span>,
          <span>
            Mai H-Q, Chen Q-Y, Chen D, et al. Toripalimab or placebo plus chemotherapy as first-line treatment in advanced nasopharyngeal carcinoma: a multicenter randomized phase 3 trial. <em>Nat Med</em>. 2021;27(9):1536-1543. doi:10.1038/s41591-021-01444-0
          </span>,
          <span>
            ClinicalTrials.gov. 55 studies found for: recurrent, metastatic | nasopharyngeal carcinoma. Accessed September 28, 2023. <ExitLink className='no-underline' url='https://clinicaltrials.gov/ct2/results?term=Recurrent%2C+Metastatic&cond=Nasopharyngeal+Carcinoma&age_v=&gndr=&type=&rslt=&phase=2&Search=Apply'>https://clinicaltrials.gov/ct2/results?term=Recurrent%2C+Metastatic&cond=Nasopharyngeal+Carcinoma&age_v=&gndr=&type=&rslt=&phase=2&Search=Apply</ExitLink>
          </span>,
          <span>
            Data on file. Coherus BioSciences, Inc.; 2023.
          </span>,
          <span>Mai H-Q, Chen Q-Y, Chen D, et al. Toripalimab plus chemotherapy for recurrent or metastatic nasopharyngeal carcinoma: the JUPITER-02 randomized clinical trial. <em>JAMA</em>. 2023;330(20):1961-1970. doi:10.1001/jama.2023.20181</span>
        ]}
        border={true} />

      <CtaDescList content={[
          {
            cta: {
              text: <>LOQTORZI<sup>®</sup> at a glance</>,
              url:'/loqtorzi-at-a-glance/',
              title:'Access to key information about LOQTORZI®'
            },
            text: <div>This module offers you quick <br className='desktop:hidden' />access to key information <br className='desktop:hidden' />about LOQTORZI<sup>®</sup> <br className='hidden large:block' />
              to help you <br className='desktop:hidden' />support your patients.</div>
          }, {
            cta: {
              text: <span>Interactive <br/>
                Prescribing Information </span>,
              url:'/loqtorzi-prescribing-information/',
              title:'Review the details of LOQTORZI® trial data'
            },
            text: <div>Explore LOQTORZI<sup>®</sup> data
              <br className='desktop:hidden' /> in the interactive
              <br className='desktop:hidden' /> Prescribing Information.</div>
          },
          {
            cta: {
              text: 'Get Updates',
              url: '/get-updates/',
              title:'Receive the latest information about LOQTORZI®'
            },
            text: <div>Register to receive the latest <br className='desktop:hidden' />information and announcements <br className='desktop:hidden' />about LOQTORZI<sup>®</sup>.</div>
          }
        ]}
      />
    </Layout>
  );
}
